import { styled } from 'styled-components';
import { darken, desaturate, lighten } from 'polished';

// TODO: Make this more than just an exported styled component
export const Select = styled.select`
  width: 100%;
  padding: 1em;
  border-radius: 6px;
  border: 1px solid ${props => lighten(0.1, props.theme.colors.highlight)};
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.typography.colors.subtle};
  background-color: ${props => props.theme.colors.highlight};

  &:disabled {
    background-color: ${props => desaturate(0.1, props.theme.colors.highlight)};
    color: ${props => darken(0.15, props.theme.typography.colors.subtle)};
  }

  &:focus {
    outline: none;
  }
`;
