import { styled } from 'styled-components';
import { useGetUsersQuery, User } from '../../api';
import { Heading } from '../../components/Heading.tsx';
import {
  ListView,
  ListViewColumnDefinition,
  ListViewFieldProps,
  rowsFromColumnDefinitions
} from '../../components/table/listview';
import { IoIosAddCircle } from 'react-icons/io';
import { Button } from '../../components/button';
import { useEffect, useState } from 'react';
import { InviteUserModal } from './InviteUserModal.tsx';
import { MdDelete, MdEdit } from 'react-icons/md';
import { DeleteUserModal } from './DeleteUserModal.tsx';
import { useAuthentication } from '../../auth/RequireAuthentication.tsx';
import { EditUserModal } from './EditUserModal.tsx';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { IconButton } from '../../components/button/IconButton.tsx';

const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  height: 54px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
`;

const ListViewContainer = styled.div`
  height: 60vh;
  margin-top: 20px;
  overflow: auto;
`;

const Pager = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const TitleCase = styled.span`
  text-transform: capitalize;
`;

const ButtonGroup = styled.div`
  display: flex;
`;

const columnDefinitions: ListViewColumnDefinition[] = [
  { field: 'email', header: 'Email Address' },
  { field: 'firstName', header: 'First Name' },
  { field: 'lastName', header: 'Last Name' },
  {
    field: 'roles',
    header: 'Role',
    fieldComponent: ({ value }: ListViewFieldProps) => {
      const roles = value as string[];
      return <TitleCase>{roles[0]}</TitleCase>;
    }
  },
  {
    field: 'id',
    header: '',
    fieldComponent: ({ dataItem }: ListViewFieldProps) => {
      const user = dataItem as User;
      return <UserActions {...user} />;
    }
  }
];

export const UsersList = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTokens, setPageTokens] = useState<Record<number, string>>({});
  const { data, isLoading } = useGetUsersQuery({
    pageToken: pageTokens[currentPage] || undefined
  });

  const rows = rowsFromColumnDefinitions(data?.items || [], columnDefinitions);

  useEffect(() => {
    if (data?.next) {
      setPageTokens({ ...pageTokens, [currentPage + 1]: data.next });
    }
  }, [data?.next]);

  return (
    <UsersContainer>
      {showModal && <InviteUserModal onClose={() => setShowModal(false)} />}

      <TopBar>
        <Heading size={'l'}>Users</Heading>
        <Actions>
          <Button
            variant="primary"
            icon={IoIosAddCircle}
            iconLayout="start"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Invite User
          </Button>
        </Actions>
      </TopBar>
      <Container>
        <ListViewContainer>
          <ListView rows={rows} columns={columnDefinitions} isLoading={isLoading} />
          <Pager>
            <IconButton
              variant={'transparent'}
              icon={FaChevronLeft}
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage <= 1 || isLoading}
            />
            <IconButton
              variant={'transparent'}
              icon={FaChevronRight}
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={!data?.next || isLoading}
            />
          </Pager>
        </ListViewContainer>
      </Container>
    </UsersContainer>
  );
};

const UserActions = (user: User) => {
  const { id } = useAuthentication();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <ButtonGroup>
      {showEditModal && <EditUserModal {...user} onClose={() => setShowEditModal(false)} />}
      {showDeleteModal && (
        <DeleteUserModal userId={user.id} email={user.email} onClose={() => setShowDeleteModal(false)} />
      )}

      <IconButton onClick={() => setShowEditModal(true)} variant={'transparent'} icon={MdEdit} />

      <IconButton
        onClick={() => setShowDeleteModal(true)}
        variant={'danger'}
        icon={MdDelete}
        disabled={user.id === id}
      />
    </ButtonGroup>
  );
};
