import { IconType } from 'react-icons';
import { styled } from 'styled-components';
import { Icon } from '../Icon.tsx';
import { ButtonHTMLAttributes, MouseEventHandler } from 'react';

type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant: 'accent' | 'danger' | 'transparent' | 'noHover';
  icon: IconType;
};

const BasicIconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.6rem;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
    opacity: 0.25;
    cursor: default;
  }
`;

const IconButtonAccent = styled(BasicIconButton)`
  background-color: ${props => props.theme.colours.accent};
  color: ${props => props.theme.colours.primary};
  transition: opacity 0.2s ease-in-out;

  &:enabled:hover {
    opacity: 0.7;
  }
`;

const IconButtonTransparent = styled(BasicIconButton)`
  background-color: transparent;
  color: ${props => props.theme.colours.light};
  transition: background-color 0.2s ease-in-out;

  &:enabled:hover {
    background-color: ${props => props.theme.colours.subtle};
  }
`;

const IconButtonTransparentDanger = styled(BasicIconButton)`
  background-color: transparent;
  color: ${props => props.theme.colours.light};
  transition: background-color 0.2s ease-in-out;

  &:enabled:hover {
    background-color: ${props => props.theme.colours.danger};
  }
`;

const IconButtonNoHover = styled(BasicIconButton)`
  background-color: transparent;
  color: ${props => props.theme.colours.light};
`;

const IconButtonContent = ({ icon }: { icon: IconType }) => {
  return <Icon size={'fillParent'} IconType={icon} />;
};

export const IconButton = ({ onClick, variant, icon, disabled }: IconButtonProps) => {
  const buttonProps = { onClick, disabled };
  const iconProps = { icon };

  if (variant === 'accent') {
    return (
      <IconButtonAccent {...buttonProps}>
        <IconButtonContent {...iconProps} />
      </IconButtonAccent>
    );
  } else if (variant === 'danger') {
    return (
      <IconButtonTransparentDanger {...buttonProps}>
        <IconButtonContent {...iconProps} />
      </IconButtonTransparentDanger>
    );
  } else if (variant === 'transparent') {
    return (
      <IconButtonTransparent {...buttonProps}>
        <IconButtonContent {...iconProps} />
      </IconButtonTransparent>
    );
  } else if (variant === 'noHover') {
    return (
      <IconButtonNoHover {...buttonProps}>
        <IconButtonContent {...iconProps} />
      </IconButtonNoHover>
    );
  } else {
    return (
      <IconButtonTransparent {...buttonProps}>
        <IconButtonContent {...iconProps} />
      </IconButtonTransparent>
    );
  }
};
