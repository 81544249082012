import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index.ts';
import { Token } from '../../api/auth.ts';
import { RESET_ALL_ACTION } from '../actions.ts';

type AuthState = {
  token?: Token;
};

const initialState = {
  token: undefined
} as AuthState;

const slice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder => builder.addCase(RESET_ALL_ACTION, () => initialState),
  reducers: {
    setToken: (state, { payload: token }: PayloadAction<Token>) => {
      state.token = token;
    }
  }
});

export const { setToken } = slice.actions;

export default slice.reducer;

export const getToken = (state: RootState): Token | undefined => state.auth.token;
