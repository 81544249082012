import { useNavigate, useParams } from 'react-router-dom';
import { useDeletePatientMutation, useGetPatientActivityLogsQuery, useGetPatientQuery } from '../../../api';
import { Heading } from '../../../components/Heading.tsx';
import { styled } from 'styled-components';
import { Button } from '../../../components/button';
import { GoPencil } from 'react-icons/go';
import { NotFound } from '../../../NotFound.tsx';
import { IoArrowBackOutline } from 'react-icons/io5';
import { Modal } from '../../../components/Modal.tsx';
import { useState } from 'react';
import { EditPatientForm } from './EditPatientForm.tsx';
import { Spinner } from '../../../components/Spinner.tsx';
import { PersonalDetailsContent } from './PersonalDetailsTile.tsx';
import { ActivityLogContent } from './ActivityLogTile.tsx';
import { Pagination } from '../../../components/Pagination.tsx';
import { useListViewState } from '../../../components/table/listview/useListViewState.ts';

const PatientContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopBarButtons = styled.div`
  display: flex;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`;

const DeleteContent = styled.div`
  margin-bottom: 20px;
`;

const ModalContent = styled.div`
  padding: 15px;
`;

const PatientDataRow = styled.div`
  display: flex;
  padding-bottom: 40px;
  max-height: 100%;
`;

const PatientDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ActionContainer = styled.div`
  display: flex;
`;

export const PatientDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletePatient, { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess }] = useDeletePatientMutation();

  const navigate = useNavigate();

  const { id } = useParams();
  const { data: patient, error } = useGetPatientQuery(id as string, {
    skip: isDeleteSuccess || isDeleteLoading
  });

  const pageSize = 100;
  const [{ page }, _, setListViewPage] = useListViewState({ page: 1 }, 'patientActivityLogList');

  const { data: activityLogQueryResult, isLoading } = useGetPatientActivityLogsQuery({
    patientId: id as string,
    page: page || 1,
    pageSize: pageSize
  });

  const totalPages = Math.ceil((activityLogQueryResult?.totalPatientActivityLogs || 0) / pageSize);

  // TODO: add more error handling and a loading state
  if (error && 'status' in error && error.status === 404) {
    return <NotFound />;
  }

  const removePatient = async () => {
    await deletePatient(id as string);
    navigate('/patients');
  };

  return (
    <PatientContainer>
      {showModal && patient && (
        <Modal title="Edit a Patient" onClose={() => setShowModal(false)}>
          <EditPatientForm oldPatient={patient} closeModal={() => setShowModal(false)} />
        </Modal>
      )}
      {showDeleteModal && patient && (
        <Modal title="Delete a Patient" onClose={() => setShowDeleteModal(false)}>
          <ModalContent>
            <DeleteContent>Are you sure you want to delete this patient?</DeleteContent>
            <ActionContainer>
              <Button variant="accent" onClick={removePatient} disabled={isDeleteLoading}>
                Delete
              </Button>
              {isDeleteLoading && <Spinner />}
            </ActionContainer>
          </ModalContent>
        </Modal>
      )}

      <TopBar>
        <Heading size={'l'}>Patients</Heading>
        <TopBarButtons>
          {patient && (
            <Button
              variant="primary"
              icon={GoPencil}
              onClick={() => {
                setShowModal(true);
              }}
            >
              Edit Patient
            </Button>
          )}
          <Button variant="primary" icon={IoArrowBackOutline} onClick={() => navigate('..')}>
            Back
          </Button>
        </TopBarButtons>
      </TopBar>
      {patient ? (
        <PatientDataRow>
          <PatientDataColumn>
            <PersonalDetailsContent patient={patient} />
            <ButtonContainer>
              <Button variant="danger" onClick={() => setShowDeleteModal(true)}>
                Delete Patient
              </Button>
            </ButtonContainer>
          </PatientDataColumn>
          <PatientDataColumn>
            {activityLogQueryResult && ActivityLogContent(activityLogQueryResult.patientActivityLogs)}
            {!isLoading && totalPages > 1 && (
              <Pagination currentPage={page || 1} totalPages={totalPages} changePage={setListViewPage} />
            )}
          </PatientDataColumn>
        </PatientDataRow>
      ) : (
        <Spinner />
      )}
    </PatientContainer>
  );
};
