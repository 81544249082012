import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider
} from 'react-router-dom';
import { styled } from 'styled-components';
import { NotFound } from './NotFound';
import { RequireAuthentication } from './auth/RequireAuthentication.tsx';
import { Layout } from './components/layout';
import { SignIn, SignInCallback } from './auth/views/SignIn.tsx';
import { Admin } from './admin';
import { Patients } from './patients';

const AppContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<RequireAuthentication />}>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route path="/" element={<Navigate to="/patients" replace={true} state={{ resetView: true }} />} />
            <Route path="/patients/*" element={<Patients />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-in/callback" element={<SignInCallback />} />
      </Route>
    )
  );

  return (
    <AppContainer>
      <RouterProvider router={router} />
    </AppContainer>
  );
};
