import { PatientActivityLog } from '../../../api';
import { styled } from 'styled-components';
import { Tile } from '../../../components/Tile.tsx';
import { Heading } from '../../../components/Heading.tsx';

const ActivityLogTile = styled(Tile)`
  margin: 40px 0 0 40px;
  display: flex;
  max-height: 100%;
`;

const ActivityLogContainer = styled.div`
  margin: 16px 0;
`;

const ActivityLogValue = styled.p`
  margin: 0 0 0 18px;
  font-size: ${props => props.theme.fontSizes.sm};
`;

type ActivityLog = {
  id: number;
  activityName: string;
  completedOn: Date;
  timeOnTask: number;
  rpe?: number;
  repsCompleted?: number;
  independenceScore?: number;
  enduranceScore?: number;
};

const activityNameMap: Record<string, string> = {
  'f6a8c0f6-d853-47bd-80a1-ced80c12396a': 'CPM',
  '86b88aaa-6ab5-4fb8-8bc9-08ea3122d949': 'Active Assist',
  '6a84abb3-af06-4fe8-83bf-17bb8d407e33': 'Manual Mode',
  '17c400cd-9c82-4118-86d5-0c9e30e6f38b': 'Game'
};

const formatDate = (date: Date) => {
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}
  ${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

const formatTime = (time: number) => {
  return `${Math.floor(time / 60)}:${(time % 60).toString().padStart(2, '0')}`;
};

export const ActivityLogContent = (activityLogs: PatientActivityLog[]) => {
  const logs: ActivityLog[] = activityLogs.map(log => ({
    id: log.id,
    activityName: activityNameMap[log.activityType.toLowerCase()],
    completedOn: new Date(log.completedOn),
    timeOnTask: log.timeTaken,
    repsCompleted: (log as any).repsCompleted,
    rpe: (log as any).feedbackScore,
    independenceScore: (log as any).independenceScore,
    enduranceScore: (log as any).enduranceScore
  }));

  return (
    <ActivityLogTile title={'Recent Activity'}>
      {logs.map(log => (
        <ActivityLogContainer key={log.id}>
          <Heading size={'sm'}>{formatDate(log.completedOn)}</Heading>
          <ActivityLogValue>{`Activity: ${log.activityName}`}</ActivityLogValue>
          <ActivityLogValue>{`Time on Task: ${formatTime(log.timeOnTask)}`}</ActivityLogValue>
          {log.repsCompleted !== undefined && log.repsCompleted !== null && (
            <ActivityLogValue>{`Reps Completed: ${log.repsCompleted}`}</ActivityLogValue>
          )}
          {log.rpe !== undefined && log.rpe !== null && <ActivityLogValue>{`RPE: ${log.rpe}`}</ActivityLogValue>}
          {log.independenceScore !== undefined && log.independenceScore !== null && (
            <ActivityLogValue>{`Independence Score: ${log.independenceScore}`}</ActivityLogValue>
          )}
          {log.enduranceScore !== undefined && log.enduranceScore !== null && (
            <ActivityLogValue>{`Endurance Score: ${log.enduranceScore}`}</ActivityLogValue>
          )}
        </ActivityLogContainer>
      ))}
    </ActivityLogTile>
  );
};
