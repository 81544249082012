import { styled } from 'styled-components';

const Loader = styled.div`
  border: 5px solid ${props => props.theme.colours.backgroundMedium};
  border-radius: 50%;
  border-top: 5px solid ${props => props.theme.colours.accent};
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
`;

export const Spinner = () => {
  return (
    <SpinnerContainer>
      <Loader />
    </SpinnerContainer>
  );
};
