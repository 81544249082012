import { Modal } from '../../components/Modal.tsx';
import { Button } from '../../components/button';
import { Spinner } from '../../components/Spinner.tsx';
import { styled } from 'styled-components';
import { useDeleteUserMutation } from '../../api';
import { useEffect } from 'react';

type DeleteUserModalProps = {
  userId: string;
  email: string;
  onClose: () => void;
};

const ConfirmModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px 20px 10px;
`;

const ActionContainer = styled.div`
  display: flex;
`;

const DeleteContent = styled.div`
  margin-bottom: 20px;
`;

const DeleteUser = ({ userId, email, onClose }: DeleteUserModalProps) => {
  const [deleteUser, { isLoading: isDeleting, isSuccess }] = useDeleteUserMutation();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <ConfirmModalContent>
      <DeleteContent>Are you sure you want to delete user {email}?</DeleteContent>
      <ActionContainer>
        <Button variant={'accent'} onClick={() => deleteUser(userId)} disabled={isDeleting}>
          Delete
        </Button>
        {isDeleting && <Spinner />}
      </ActionContainer>
    </ConfirmModalContent>
  );
};

export const DeleteUserModal = ({ onClose, ...props }: DeleteUserModalProps) => {
  return (
    <Modal title="Delete User" onClose={onClose}>
      <DeleteUser onClose={onClose} {...props} />
    </Modal>
  );
};
