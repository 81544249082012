import { Route, Routes } from 'react-router-dom';
import { PatientsList } from './views/PatientsList.tsx';
import { PatientDetails } from './views/details';

export const Patients = () => {
  return (
    <Routes>
      <Route index element={<PatientsList />} />
      <Route path="/:id" element={<PatientDetails />} />
    </Routes>
  );
};
