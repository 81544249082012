import React from 'react';

type ErrorBoundaryState = {
  error?: Error;
  errorMessage?: string;
};

type ErrorBoundaryProps = {
  children: React.ReactNode;
  isComponentError?: boolean;
};

type ErrorBoundaryContentProps = {
  isComponentError?: boolean;
  error?: Error;
};

const ErrorBoundaryContent = ({ isComponentError, error }: ErrorBoundaryContentProps) => {
  let message = 'Something went wrong when trying to display this content';
  if (isComponentError && error && error.message) {
    message = error.message;
  }

  return (
    <div>
      <h2>Something went wrong</h2>
      <p>{message}</p>
    </div>
  );
};

// TODO: Hook in client-side error logging service
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render(): React.ReactNode {
    const { error } = this.state;
    const { children, isComponentError } = this.props;

    if (!error) {
      return children;
    }

    return <ErrorBoundaryContent isComponentError={isComponentError} error={error} />;
  }
}
