import { useState } from 'react';
import { TextInput } from '../../components/inputs/TextInput.tsx';
import { Button } from '../../components/button';
import { styled } from 'styled-components';
import { useAddPatientMutation } from '../../api';
import { Spinner } from '../../components/Spinner.tsx';
import { useValidator } from '../../hooks/useValidator.ts';
import { constants } from '../../constants.ts';

type AddPatientFormProps = {
  closeModal: () => void;
};

const InputContainer = styled.div`
  margin-top: 20px;
  max-width: 400px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const PatientForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
`;

const FormErrorContainer = styled.div`
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  margin-left: 20px;
`;

export const AddPatientForm = ({ closeModal }: AddPatientFormProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [formError, setFormError] = useState('');

  const { validator, validate } = useValidator({
    validName: {
      message: 'Please enter a valid name',
      rule: (val: string) => {
        return constants.regExp.findName.test(val);
      }
    }
  });

  const [addPatient, { isLoading: isUpdating }] = useAddPatientMutation();

  const handleFirstNameChange = (e: string) => {
    setFirstName(e);
  };

  const handleLastNameChange = (e: string) => {
    setLastName(e);
  };

  const submit = async () => {
    if (validate()) {
      try {
        const newPatient = { firstName, lastName };
        await addPatient(newPatient);
        closeModal();
      } catch (error) {
        setFormError('Error adding patient');
      }
    }
  };

  return (
    <PatientForm>
      <InputContainer>
        <TextInput
          label="First name*"
          placeholder="Enter first name"
          onChange={handleFirstNameChange}
          errorMessage={validator.current.message('firstName', firstName, 'required|validName')}
        />
      </InputContainer>
      <InputContainer>
        <TextInput
          label="Last name*"
          placeholder="Enter last name"
          onChange={handleLastNameChange}
          errorMessage={validator.current.message('lastName', lastName, 'required|validName')}
        />
      </InputContainer>
      <ButtonContainer>
        <Button variant="accent" onClick={() => submit()} disabled={isUpdating}>
          Add New Patient
        </Button>
        {isUpdating && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
      </ButtonContainer>
      {formError && <FormErrorContainer>{formError}</FormErrorContainer>} {/*Move this to toast or similar*/}
    </PatientForm>
  );
};
