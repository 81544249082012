import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { Normalize } from 'styled-normalize';
import { App } from './App.tsx';
import { GlobalStyles } from './GlobalStyles.tsx';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary } from './components/ErrorBoundary.tsx';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <React.Suspense fallback={<div>Loading...</div>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <Normalize />
            <GlobalStyles />
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </React.Suspense>
  </React.StrictMode>
);
