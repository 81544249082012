import { styled } from 'styled-components';
import { AppHeader } from './AppHeader.tsx';
import { ConstrainedContent } from './ConstrainedContent.tsx';
import React from 'react';
import { ErrorBoundary } from '../ErrorBoundary.tsx';

const AppRoot = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ContentContainer = styled(ConstrainedContent)`
  padding-top: 2rem;
`;

export const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <AppRoot>
      <AppHeader />
      <ContentContainer>
        <ErrorBoundary>{children}</ErrorBoundary>
      </ContentContainer>
    </AppRoot>
  );
};
