import { styled } from 'styled-components';
import { ReactNode } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { Heading } from './Heading.tsx';
import { createPortal } from 'react-dom';
import { IconButton } from './button/IconButton.tsx';
import { ErrorBoundary } from './ErrorBoundary.tsx';

interface ModalProps {
  children: ReactNode;
  onClose: () => void;
  title: string;
  rootElement?: HTMLElement;
}

const ModalOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colours.overlay};
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }
  animation: 0.2s ease-out 0s 1 fadeIn;
`;

const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 70%;
  max-height: 70%;
  padding: 20px;
  border-radius: 20px;
  background-color: ${props => props.theme.colours.primary};
  @keyframes popIn {
    0% {
      transform: translateY(50%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  animation: 0.2s ease-out 0s 1 popIn;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: 14px;
`;

const ContentContainer = styled.div`
  margin-top: 12px;
  overflow-y: auto;
`;

export const Modal = ({ children, onClose, title, rootElement }: ModalProps) => {
  return createPortal(
    <ModalOverlay onClick={onClose}>
      <ModalBox onClick={e => e.stopPropagation()}>
        <ErrorBoundary>
          <TopContainer>
            <Heading size={'m'}>{title}</Heading>
            <IconButton onClick={onClose} variant={'transparent'} icon={RxCross1} />
          </TopContainer>
          <ContentContainer>{children}</ContentContainer>
        </ErrorBoundary>
      </ModalBox>
    </ModalOverlay>,
    rootElement || document.body
  );
};
