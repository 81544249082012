export const theme = {
  layout: {
    contentWidth: '1400px',
    background: '#1e293a'
  },
  typography: {
    colors: {
      primary: '#ffffff',
      secondary: '#05152b',
      subtle: '#acb1b7'
    }
  },
  colors: {
    accent: '#00dced',
    subtle: '#656e7d',
    danger: '#881714',
    highlight: '#303b4b',
    error: '#d38c0a'
  },
  colours: {
    background: '#242424',
    backgroundDark: '#05152b',
    backgroundMedium: '#16465a',
    backgroundLight: '#354b59',
    primary: '#1e293a',
    accent: '#00DCED',
    subtle: '#656e7d',
    text: '#000000',
    outline: '#808080',
    light: '#ffffff',
    dark: '#000000',
    overlay: '#000000b3',
    danger: '#881714'
  },
  fontSizes: {
    xsm: '12px',
    sm: '16px',
    m: '24px',
    l: '32px'
  },
  fontWeights: {
    thin: '500',
    regular: '600',
    bold: '700'
  }
};
